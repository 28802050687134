@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .login-input-label {
    @apply text-[#262628] font-[500] text-[14px];
  }

  .login-input {
    @apply px-4 py-2 2xl:h-[48px] h-[44px] border border-[#E4E4E4] bg-white rounded-[3px] text-[#262628] text-[15px] font-[400px] leading-normal focus-visible:outline-none placeholder:text-[14px] placeholder:text-[#262628];
  }

  .login-button {
    @apply w-full px-4 py-3 rounded-[6px] flex justify-center items-center border border-theme-color bg-white text-black text-[16px] font-bold h-[40px] focus-visible:outline-none hover:border-white hover:bg-[transparent] hover:text-white;
  }

  .custom-button {
    @apply px-4 py-2 rounded-[6px] flex justify-center items-center text-white bg-theme-color text-[14px] font-medium hover:border-white hover:bg-theme_secondary focus-visible:outline-none;
  }

  .primary_btn {
    @apply px-4 py-2 rounded-[6px] flex justify-center items-center text-white bg-theme-color text-[14px] font-semibold hover:border-white hover:bg-theme_secondary focus-visible:outline-none max-w-[300px] w-full ;
  }
  .secondary_btn {
    @apply px-4 py-2 rounded-[6px] flex justify-center items-center text-[gray] border text-[14px] font-semibold hover:border-[gray] hover:bg-menu_secondary focus-visible:outline-none max-w-[300px] w-full;
  }

  td {
    @apply text-[14px] font-[400] py-3 px-5 border-b
  }
}

@layer base {
  html {
    /* / font-family: 'Montserrat', sans-serif; / */
    font-family: 'Roboto', sans-serif;
  }
}

@font-face {
  font-family: 'Mukta';
  src: url('./assets/fonts/Mukta/Mukta-Regular.ttf') format('ttf'); 
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mukta';
  src: url('./assets/fonts/Mukta/Mukta-Medium.ttf') format('ttf'); 
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Mukta';
  src: url('./assets/fonts/Mukta/Mukta-SemiBold.ttf') format('ttf'); 
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mukta';
  src: url('./assets/fonts/Mukta/Mukta-Bold.ttf') format('ttf'); 
  font-weight: 700;
  font-style: normal;
}

body{
  /* font-family: 'Roboto Mono', monospace; */
}

th,
td {
  border: none !important;
  border-bottom: 1px solid #81828345 !important;
}

button {
  transition: 0.3s;
}

button:hover {
  webkit-transition: all 350ms ease-in-out 0s;
  -moz-transition: all 350ms ease-in-out 0s;
  -ms-transition: all 350ms ease-in-out 0s;
  -o-transition: all 350ms ease-in-out 0s;
  transition: all 350ms ease-in-out 0s;
  opacity: 1;
}

/* / scroll bar / */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(82, 110, 119);
  border-radius: 6px;
}

/* / For Firefox /
/ Note: Firefox supports only scrollbar-color and scrollbar-width /
/ scrollbar-color: thumb color track color /
/ scrollbar-width: thin | auto | none / */
html {
  scrollbar-color: #fff #f690f66f;
  scrollbar-width: thin;
}


/* / login-bg / */
.login-bg {
  background: linear-gradient(45deg, #343a47, transparent);
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

}
.login-btn{
  background: linear-gradient(46deg,#101930,#535bb5fa);
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  border:1px solid transparent;
  transition: all 0.6s ease-in-out, color 0.3s ease;
}

.login-btn:hover{
  color: #f3f3f3;
  background: linear-gradient(46deg,#535bb5fa,#101930);
}

.dash-menu svg {
  fill: white !important;
  stroke: white !important;
  height: 20px;
  width: 20px;
}

.head-menu svg {
  height: 30px;
  width: 30px;
}
.side-profile svg {
  height: 25px;
  width: 25px;
}

.custom-input {
  filter: drop-shadow(-2.828px 2.828px 1.5px rgba(0, 0, 0, .05));
  height: 50px;
  /* background-color: #fff; */
  border-color: #f3f3f3;
  border-radius: 10px;
}
.custom-input:focus{
  border: 1px solid #2c4783;
}
.is-show{
  transition:  show ;
  transform: translateX(0);
}

.drawer {
  transition: transform 0.3s ease-in-out; /* You can adjust the duration and easing function */
}
.contact-details svg{
  fill : black
}
